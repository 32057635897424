var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { background, bottom, left, position, width } from 'styled-system';
import { themeHeight } from '../../../theme/system-utilities';
var Component = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), themeHeight, position, bottom, left, width, background);
var styles = {
    themeHeight: ['sp_48', null, null, '56px'],
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 1,
    background: 'linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 126.67%)',
};
var Shadow = function () { return React.createElement(Component, __assign({}, styles)); };
export default Shadow;
var templateObject_1;
