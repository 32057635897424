var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { position, display, width, textAlign, space, themeGet, } from 'styled-system';
import { Heading } from '../../Text';
var Title = styled(Heading)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, display, width, textAlign, space, function (props) {
    return props.active && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      cursor: pointer;\n      /* The support is not that good, but most of the modern mobile browsers are supporting this */\n      /* https://caniuse.com/#feat=css-media-interaction */\n      @media not all and (pointer: coarse) {\n        &:hover {\n          color: ", ";\n        }\n      }\n    "], ["\n      cursor: pointer;\n      /* The support is not that good, but most of the modern mobile browsers are supporting this */\n      /* https://caniuse.com/#feat=css-media-interaction */\n      @media not all and (pointer: coarse) {\n        &:hover {\n          color: ", ";\n        }\n      }\n    "])), themeGet('colors.sys.primary.background.default')(props));
});
Title.defaultProps = __assign(__assign({}, Heading.defaultProps), { as: 'h3', position: 'relative', display: 'block', textAlign: 'left', width: 1, m: 'sp_0', mb: 'sp_0', px: 'sp_0', pt: 'sp_16', pb: ['sp_16', null, 'sp_8'], color: 'sys.neutral.text.default', fontSize: ['fs_16', null, null, 'fs_18'], lineHeight: ['lh_24', null, null, null] });
export default Title;
var templateObject_1, templateObject_2;
