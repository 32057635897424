var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useState } from 'react';
var filterOutCollapsed = function (expandedSections, sectionName) {
    return expandedSections.filter(function (name) { return name !== sectionName; });
};
var getIsSectionExpanded = function (expandedSections, sectionName) { return expandedSections.includes(sectionName); };
var getSections = function (expandedSections, sectionName) {
    return expandedSections.includes(sectionName)
        ? filterOutCollapsed(expandedSections, sectionName)
        : __spreadArray(__spreadArray([], __read(expandedSections), false), [sectionName], false);
};
var useToggleHandler = function (_a) {
    var isCollapsible = _a.isCollapsible, expandedSections = _a.expandedSections, setExpandedSections = _a.setExpandedSections;
    return function (sectionName) {
        if (!isCollapsible) {
            return;
        }
        var newSections = getSections(expandedSections, sectionName);
        setExpandedSections(newSections);
    };
};
export var useAccordion = function (isCollapsible, defaultExpandedSections) {
    if (isCollapsible === void 0) { isCollapsible = true; }
    if (defaultExpandedSections === void 0) { defaultExpandedSections = []; }
    var _a = __read(useState(defaultExpandedSections), 2), expandedSections = _a[0], setExpandedSections = _a[1];
    var toggleHandler = useToggleHandler({
        isCollapsible: isCollapsible,
        expandedSections: expandedSections,
        setExpandedSections: setExpandedSections,
    });
    var isSectionExpanded = function (name) {
        return !isCollapsible || getIsSectionExpanded(expandedSections, name);
    };
    return [
        useCallback(toggleHandler, [isCollapsible, expandedSections]),
        useCallback(isSectionExpanded, [isCollapsible, expandedSections]),
    ];
};
export var useAccordionToggle = function (identifier) {
    var _a = __read(useAccordion(), 2), toggle = _a[0], isExpanded = _a[1];
    var toggleAccordion = useCallback(function () { return toggle(identifier); }, [
        toggle,
        identifier,
    ]);
    var expanded = isExpanded(identifier);
    return [expanded, toggleAccordion];
};
