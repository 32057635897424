var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { alignItems, backgroundColor, border, borderColor, borderRadius, borderStyle, borderWidth, color, display, justifyContent, maxHeight, maxWidth, size, space, textAlign, themeGet, width, } from 'styled-system';
import { isTouchDevice } from '../../DeviceDetector';
import { cursor, fill } from '../../theme/system-utilities';
import Icon from '../Icon';
var ICON_SIZE = 16;
var Button = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font: inherit;\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", "\n"], ["\n  font: inherit;\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", "\n"])), alignItems, backgroundColor, borderRadius, border, color, cursor, display, justifyContent, space, textAlign, width, function (props) {
    return props.withTopBorderRadius && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      border-top-left-radius: ", ";\n      border-top-right-radius: ", ";\n    "], ["\n      border-top-left-radius: ", ";\n      border-top-right-radius: ", ";\n    "])), themeGet('radii.medium')(props), themeGet('radii.medium')(props));
}, function (props) {
    return props.withBottomBorderRadius && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      border-bottom-left-radius: ", ";\n      border-bottom-right-radius: ", ";\n    "], ["\n      border-bottom-left-radius: ", ";\n      border-bottom-right-radius: ", ";\n    "])), themeGet('radii.medium')(props), themeGet('radii.medium')(props));
}, function () {
    return isTouchDevice() && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      outline: none;\n    "], ["\n      outline: none;\n    "])));
});
var ButtonStyles = {
    alignItems: 'center',
    backgroundColor: 'sys.neutral.background.default',
    border: 'none',
    borderRadius: 0,
    color: 'sys.neutral.text.strong',
    cursor: 'pointer',
    display: 'flex',
    fontSize: ['fs_16', 'fs_18', null, 'fs_20'],
    justifyContent: 'space-between',
    m: 'sp_0',
    p: 'sp_0',
    textAlign: 'left',
    width: 1,
};
var ButtonWithHover = styled(Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  &:hover {\n    border-radius: ", ";\n    border-color: ", ";\n    position: relative;\n  }\n\n  &:focus-visible {\n    outline: 1px solid ", ";\n    border: 1px solid ", ";\n    border-radius: ", ";\n    outline-offset: -", "px;\n    position: relative;\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  &:hover {\n    border-radius: ", ";\n    border-color: ", ";\n    position: relative;\n  }\n\n  &:focus-visible {\n    outline: 1px solid ", ";\n    border: 1px solid ", ";\n    border-radius: ", ";\n    outline-offset: -", "px;\n    position: relative;\n  }\n"])), borderColor, borderStyle, borderWidth, maxWidth, function (props) {
    return !props.keepBorderWhenExpanded &&
        props.hasBottomBorder && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      border-bottom-color: ", ";\n    "], ["\n      border-bottom-color: ", ";\n    "])), themeGet('colors.sys.neutral.border.weak')(props));
}, function (props) {
    return props.keepBorderWhenExpanded && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      border-top-color: ", ";\n    "], ["\n      border-top-color: ", ";\n    "])), themeGet('colors.sys.neutral.border.weak')(props));
}, themeGet('radii.medium'), themeGet('colors.sys.neutral.border.hover'), themeGet('colors.sys.secondary.border.default'), themeGet('colors.sys.secondary.border.default'), themeGet('radii.medium'), themeGet('space.sp_2'));
var ButtonWithHoverStyles = __assign(__assign({}, ButtonStyles), { borderColor: 'transparent', borderWidth: '1px', border: '1px', borderStyle: 'solid', px: ['sp_16', null, null, 'sp_24'], py: ['sp_20', null, 'sp_24'] });
var Arrow = styled(Icon)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  transform: rotate(", "deg);\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  transform: rotate(", "deg);\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), function (props) { return (props.down ? 0 : 180); }, fill, maxHeight, maxWidth, size);
Arrow.defaultProps = {
    size: ICON_SIZE,
    maxWidth: ICON_SIZE,
    maxHeight: ICON_SIZE,
    fill: 'sys.neutral.border.hover',
    role: 'presentation',
};
var SectionButton = function (_a) {
    var sectionTitleId = _a.sectionTitleId, sectionRegionId = _a.sectionRegionId, isExpanded = _a.isExpanded, children = _a.children, withHover = _a.withHover, hasBottomBorder = _a.hasBottomBorder, withTopBorderRadius = _a.withTopBorderRadius, withBottomBorderRadius = _a.withBottomBorderRadius, iconStyles = _a.iconStyles, sectionBtnStyles = _a.sectionBtnStyles, keepBorderWhenExpanded = _a.keepBorderWhenExpanded;
    var SectionBtn = withHover ? ButtonWithHover : Button;
    var _b = iconStyles || {}, name = _b.name, restIconStyles = __rest(_b, ["name"]);
    return (React.createElement(SectionBtn, __assign({ "aria-expanded": isExpanded, "aria-controls": sectionRegionId, id: sectionTitleId, "data-testid": "accordion-button", hasBottomBorder: !isExpanded && hasBottomBorder, keepBorderWhenExpanded: keepBorderWhenExpanded, withTopBorderRadius: withTopBorderRadius, withBottomBorderRadius: withBottomBorderRadius }, (sectionBtnStyles && sectionBtnStyles), (withHover ? ButtonWithHoverStyles : ButtonStyles)),
        children,
        React.createElement(Arrow, __assign({ down: !isExpanded, name: name || 'arrowDownStrong' }, (iconStyles && restIconStyles)))));
};
export default SectionButton;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
