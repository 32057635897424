var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { color, display, height, left, position, right } from 'styled-system';
var SECTION_POSITION = ['-16px', '-24px', '-48px', '-64px'];
var Border = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), height, position, color, display, left, right);
Border.defaultProps = {
    position: 'absolute',
    height: '1px',
    bg: 'sys.neutral.border.weak',
    display: 'block',
    left: SECTION_POSITION,
    right: SECTION_POSITION,
};
export default Border;
var templateObject_1;
