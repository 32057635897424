var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { display, flex, height, overflow, position, width } from 'styled-system';
import { useIsObserved } from '../../../common/hooks/intersection-observer';
import { boxSizing, maskImage, transition } from '../../theme/system-utilities';
import BorderTop from './Border/BorderTop';
import { DEFAULT_RENDER_BORDER_BOTTOM } from './Border/utils';
import { useRenderContent } from './hooks';
import SectionButton from './SectionButton';
import Shadow from './Shadow';
import { DEFAULT_RENDER_TITLE } from './Title/utils';
var ACCORDION_TRANSITION_DURATION = 300;
var SECTION_POSITION = ['-16px', '-24px', '-48px', '-64px'];
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), boxSizing, flex, position, width);
var containerStyles = {
    boxSizing: 'border-box',
    flex: 'none',
    position: 'relative',
    width: 1,
};
var ContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, height, overflow, transition, maskImage);
var contentWrapperStyles = {
    overflow: 'hidden',
    transition: "height ".concat(ACCORDION_TRANSITION_DURATION, "ms"),
};
var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  visibility: ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  visibility: ", ";\n"])), boxSizing, display, flex, position, transition, width, function (_a) {
    var isExpanded = _a.isExpanded;
    return (isExpanded ? 'visible' : 'hidden');
});
var contentStyles = {
    position: 'relative',
    flex: 'none',
    width: 1,
    boxSizing: 'border-box',
    transition: "visibility ".concat(ACCORDION_TRANSITION_DURATION, "ms"),
};
// eslint-disable-next-line complexity,  max-lines-per-function
var AccordionSection = function (_a) {
    var _b = _a.isCollapsible, isCollapsible = _b === void 0 ? false : _b, children = _a.children, title = _a.title, isExpanded = _a.isExpanded, onTitleClick = _a.onTitleClick, hasTopBorder = _a.hasTopBorder, keepBorderWhenExpanded = _a.keepBorderWhenExpanded, _c = _a.hasBottomBorder, hasBottomBorder = _c === void 0 ? true : _c, sectionName = _a.sectionName, _d = _a.renderTitle, renderTitle = _d === void 0 ? DEFAULT_RENDER_TITLE : _d, _e = _a.renderBottomBorder, renderBottomBorder = _e === void 0 ? DEFAULT_RENDER_BORDER_BOTTOM : _e, displayBorder = _a.displayBorder, displayTitle = _a.displayTitle, keepContentWhenCollapsed = _a.keepContentWhenCollapsed, scrollIntoView = _a.scrollIntoView, _f = _a.positionX, positionX = _f === void 0 ? SECTION_POSITION : _f, onObserved = _a.onObserved, _g = _a.isAnimated, isAnimated = _g === void 0 ? false : _g, contentHeight = _a.contentHeight, withHover = _a.withHover, showMore = _a.showMore, withTopBorderRadius = _a.withTopBorderRadius, withBottomBorderRadius = _a.withBottomBorderRadius, isV2 = _a.isV2, sectionBtnStyles = _a.sectionBtnStyles, iconStyles = _a.iconStyles, props = __rest(_a, ["isCollapsible", "children", "title", "isExpanded", "onTitleClick", "hasTopBorder", "keepBorderWhenExpanded", "hasBottomBorder", "sectionName", "renderTitle", "renderBottomBorder", "displayBorder", "displayTitle", "keepContentWhenCollapsed", "scrollIntoView", "positionX", "onObserved", "isAnimated", "contentHeight", "withHover", "showMore", "withTopBorderRadius", "withBottomBorderRadius", "isV2", "sectionBtnStyles", "iconStyles"]);
    var renderContent = useRenderContent({
        isExpanded: isExpanded,
        keepContentWhenCollapsed: keepContentWhenCollapsed,
    });
    var sectionContainerId = "accordion-section-container-".concat(sectionName);
    var sectionTitleId = "accordion-section-title-".concat(sectionName);
    var sectionRegionId = "accordion-section-region-".concat(sectionName);
    var containerRef = useRef(null);
    var borderProps = {
        display: displayBorder,
        left: positionX,
        right: positionX,
    };
    var _h = __read(useIsObserved(), 2), observerRef = _h[0], isObserved = _h[1];
    var setElementRef = function (element) {
        containerRef.current = element;
        observerRef(element);
    };
    useEffect(function () {
        var _a;
        if (scrollIntoView) {
            (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
        }
    }, [scrollIntoView]);
    useEffect(function () {
        if (isObserved && !!onObserved) {
            onObserved(sectionName);
        }
    }, [isObserved]);
    var contentWrapperUpdatedStyles = isAnimated
        ? { height: isExpanded ? 'auto' : 0, style: { height: contentHeight } }
        : { display: isExpanded ? 'block' : 'none' };
    var expandedBottom = __assign(__assign({}, borderProps), (isV2 && { left: 0, right: 0 }));
    return (React.createElement(Container, __assign({ id: sectionContainerId, "data-section-name": sectionName, "data-testid": "accordion-section", ref: setElementRef }, containerStyles, props),
        renderTitle(__assign({ 'data-testid': 'accordion-title', onClick: function () { return isCollapsible && onTitleClick(sectionName); }, active: isCollapsible, children: (React.createElement(React.Fragment, null,
                hasTopBorder && React.createElement(BorderTop, __assign({}, borderProps)),
                isCollapsible ? (React.createElement(SectionButton, { sectionTitleId: sectionTitleId, sectionRegionId: sectionRegionId, isExpanded: isExpanded, keepBorderWhenExpanded: keepBorderWhenExpanded, hasBottomBorder: hasBottomBorder, withHover: withHover, withTopBorderRadius: withTopBorderRadius, withBottomBorderRadius: withBottomBorderRadius, iconStyles: iconStyles, sectionBtnStyles: sectionBtnStyles }, title)) : (title),
                ((isV2 && !isExpanded) || !isV2) &&
                    hasBottomBorder &&
                    !withHover &&
                    renderBottomBorder(borderProps))) }, (displayTitle && { display: displayTitle }))),
        (renderContent || isAnimated) && (React.createElement(ContentWrapper, __assign({}, contentWrapperStyles, contentWrapperUpdatedStyles, { "data-testid": "accordion-section-content-wrapper" }),
            React.createElement(Content, __assign({}, contentStyles, { id: sectionRegionId, isExpanded: isExpanded, role: "region", "aria-labelledby": sectionTitleId, "data-testid": "accordion-section-content" }), children),
            showMore && isExpanded && React.createElement(Shadow, null),
            hasBottomBorder && !withHover && renderBottomBorder(expandedBottom)))));
};
export default AccordionSection;
var templateObject_1, templateObject_2, templateObject_3;
